import { useEffect } from 'react';
import { useLoginEndMutation } from 'redux/services/userApi';
import { useGetUserInfo } from 'hooks/useGetUserLoginAndUserInfo';
import { useNavigate } from 'react-router-dom';

const useAuthentication = () => {
  const navigate = useNavigate();
  const [loginEnd, { data: loginEndResponse, isLoading, isError }] =
    useLoginEndMutation();

  const isLoggedIn = loginEndResponse?.isLoggedIn;

  useEffect(() => {
    loginEnd(window.location.href);
  }, [loginEnd]);

  useEffect(() => {
    const rootUrl = window.location.origin;
    if (
      isLoggedIn === true &&
      (window.location.href.includes('signin-oidc') ||
          window.location.href.includes('undefined') ||
          window.location.href.includes('code'))
    )
      window.history.replaceState(null, '', rootUrl + '/');
    isError && navigate('/oops');
  }, [loginEndResponse, isError]);

  return { isLoggedIn, isLoading, isError };
};

export default useAuthentication;
