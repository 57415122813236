import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
  useTheme,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

export const Category = ({
  category,
  children,
  isExpanded,
  handleChange,
  index,
  ...props
}) => {
  const { palette } = useTheme();
  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleChange}
      sx={{
        mb: '13px',
      }}
      elevation={1}
    >
      <AccordionSummary
        expandIcon={
          isExpanded ? (
            <RemoveCircleIcon
              sx={{
                color: palette.blue[500],
                minHeight: '22px',
                minWidth: '22px',
              }}
              id={`collapseIcon${index}`}
            />
          ) : (
            <AddCircleIcon
              sx={{
                color: palette.blue[500],
                minHeight: '22px',
                minWidth: '22px',
              }}
              id={`expandIcon${index}`}
            />
          )
        }
        sx={{ height: '72px', padding: '0 16px' }}
      >
        <Typography id={`category${index}`}>{category}</Typography>
      </AccordionSummary>
      <AccordionDetails {...props} sx={{ padding: '8px 16px' }}>
        <Divider light sx={{ mb: '20px' }} />
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
