import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: {},
  isLoggedIn: false,
  isAdmin: false,
  isAuthLO: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.userData = payload;
    },
    setLoginStatus: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    setUserAdmin: (state, { payload }) => {
      state.isAdmin = payload;
    },
    setUserIsAuthLO: (state, { payload }) => {
      state.isAuthLO = payload;
    },
  },
});

export const { setUser, setLoginStatus, setUserAdmin, setUserIsAuthLO } =
  userSlice.actions;

export default userSlice.reducer;
