import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';

export const Dropdown = forwardRef(
  (
    {
      question,
      name,
      control,
      id,
      inputProps,
      helpertext,
      error,
      type,
      label,
      link,
      subLabel,
      href,
      options,
      disabled,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        render={({ field: { onChange, onBlur, value, ...field } }) => (
          <FormControl variant="outlined" fullWidth margin="normal">
            <Select
              sx={{ width: '340px' }}
              {...field}
              inputRef={ref}
              id={id}
              label={label}
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              error={error}
              control={control}
              disabled={disabled}
            >
              {options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText error>{helpertext}</FormHelperText>}
          </FormControl>
        )}
      />
    );
  },
);
