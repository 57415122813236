import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { InputAdornment, TextField } from '@mui/material';
import { NumericFormat, PatternFormat } from 'react-number-format';

export const TextArea = forwardRef(
  (
    {
      question,
      name,
      control,
      id,
      inputProps,
      helpertext,
      error,
      type,
      label,
      value,
      ...props
    },
    ref,
  ) => {
    let textArea;
    switch (question.possibleAnswers?.[0]) {
      case '{{#}}':
        textArea = (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericFormat
                sx={{ width: '340px' }}
                InputLabelProps={{ shrink: true }}
                inputRef={ref}
                customInput={TextField}
                id={id}
                inputProps={inputProps}
                error={error}
                helperText={helpertext}
                variant="outlined"
                margin="normal"
                value={value ?? value}
                //start react-number-format props
                allowNegative={false}
                decimalScale={0}
                isAllowed={(values) => {
                  const { formattedValue, value } = values;
                  return formattedValue === '' || value <= 9999999999999;
                }}
                onValueChange={(values) => {
                  onChange((value = values.value));
                }}
              />
            )}
          />
        );
        break;
      case '{{$}}':
        textArea = (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericFormat
                sx={{ width: '340px' }}
                InputLabelProps={{ shrink: true }}
                inputRef={ref}
                customInput={TextField}
                id={id}
                inputProps={inputProps}
                error={error}
                helperText={helpertext}
                variant="outlined"
                margin="normal"
                value={value ?? value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                //start react-number-format props
                thousandSeparator=","
                decimalScale={2}
                fixedDecimalScale
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return (
                    formattedValue === '' || floatValue <= 9999999999999.99
                  );
                }}
                onValueChange={(values) => {
                  onChange((value = values.formattedValue));
                }}
              />
            )}
          />
        );
        break;
      case '{{%}}':
        textArea = (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericFormat
                sx={{ width: '340px' }}
                format="none"
                InputLabelProps={{ shrink: true }}
                inputRef={ref}
                customInput={TextField}
                id={id}
                inputProps={inputProps}
                error={error}
                helperText={helpertext}
                variant="outlined"
                margin="normal"
                value={value ?? value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                //start react-number-format props
                decimalScale={3}
                allowNegative={false}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return (
                    formattedValue === '' || floatValue <= 9999999999999.999
                  );
                }}
                onValueChange={(values) => {
                  onChange((value = values.formattedValue));
                }}
              />
            )}
          />
        );
        break;
      case '{{PhoneNumber}}':
        textArea = (
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <PatternFormat
                mask=""
                format="(###) ###-####"
                inputRef={ref}
                id={id}
                customInput={TextField}
                type={type || 'string'}
                label={label}
                error={error}
                helperText={helpertext}
                variant="outlined"
                margin="normal"
                onValueChange={(values) => {
                  onChange((value = values?.value));
                }}
                fullWidth
                value={value || ''}
              />
            )}
          />
        );
        break;
      default:
        textArea = (
          <TextField
            sx={{ width: '340px' }}
            variant="outlined"
            margin="normal"
            inputRef={ref}
            name={name}
            label={label}
            error={error}
            helperText={helpertext}
            value={value}
            fullWidth
            inputProps={{ maxLength: 500, minLength: 0 }}
            id={id}
            {...props}
          />
        );
    }
    return textArea;
  },
);
