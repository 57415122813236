import { Stack } from '@mui/material';
import { UWMLogo, Spinner } from 'common/components';

export const LoadingBackdrop = () => (
  <>
    <Stack direction={'column'} alignItems={'center'} sx={{ mt: 12 }}>
      <UWMLogo />
      <Spinner sx={{ mt: 4 }} />
    </Stack>
  </>
);
