export const apiEndPoints = {
  // BFF
  GET_STATE_DISCLOSURES: '/DisclosureQuestions/GetStateDisclosures',
  SAVE_DISCLOSURE_DOCUMENT_ANSWERS:
    '/DisclosureQuestions/SaveDisclosureDocumentAnswers',
  GET_USER_PROFILE: '/UserProfile/GetUserProfile',
  IS_USER_ADMIN: '/UserProfile/IsUserAdmin',
  IS_AUTH_LO: '/UserProfile/IsAuthLO',

  // oauth endpoints
  LOGIN_START: '/OAuth-Agent/Login/Start',
  LOGIN_END: '/OAuth-Agent/Login/End',
  CLAIMS: '/OAuth-Agent/Claims',
  REFRESH_TOKEN: '/OAuth-Agent/Refresh',
  GET_USER_INFO: '/OAuth-Agent/UserInfo',
  LOGOUT: '/OAuth-Agent/Logout',
};
