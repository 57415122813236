import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndPoints } from 'common/constants/apiEndpoints';
import { Navigate } from 'react-router-dom';

const getUrl = () => {
  if (
    process.env.REACT_APP_API_BASE_URL === undefined ||
    process.env.REACT_APP_API_BASE_URL === null
  ) {
    return '/api';
  }
  return process.env.REACT_APP_API_BASE_URL;
};

const baseQuery = fetchBaseQuery({
  baseUrl: getUrl(),
  prepareHeaders(headers) {
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('Access-Control-Expose-Headers', '*');
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');
    return headers;
  },
  mode: 'cors',
  credentials: 'include',
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  const csrf = sessionStorage.getItem('x-disclosure-settings-csrf');
  const OAuthEndpoints = [
    'loginStart',
    'loginEnd',
    'logout',
    'refresh',
    'logoutUser',
  ];
  const isOAuthEndpoint = OAuthEndpoints.includes(api?.endpoint);
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error) {
    if (result?.error?.status === 401 && !isOAuthEndpoint) {
      const refreshResult = await baseQuery(
        {
          url: apiEndPoints.REFRESH_TOKEN,
          method: 'POST',
          headers: {
            'x-disclosure-settings-csrf': csrf,
          },
        },
        api,
        extraOptions,
      );
      if (refreshResult.meta?.response?.ok === true) {
        result = await baseQuery(args, api, extraOptions);
      } else {
        const logoutResponse = await baseQuery(
          {
            url: apiEndPoints.LOGOUT,
            method: 'POST',
            headers: {
              'x-disclosure-settings-csrf': csrf,
            },
          },
          api,
          extraOptions,
        ).then(() => {
          return <Navigate to="/oops" />;
        });
      }
    } else {
      result = await baseQuery(args, api, extraOptions);
      if (result?.error) {
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }

  return result;
};
