import { Typography, Button, Box, Link } from '@mui/material';
import { SectionImg } from '../components/SectionImg';

export const PageTemplate = ({
  img,
  imgDesc,
  title,
  descText,
  descText2,
  buttonText,
  onClick,
  path,
  displayContactMessage = true,
}) => {
  return (
    <Box
      sx={{
        margin: 'auto',
        marginTop: { xs: 0, sm: '6rem' },
        marginBottom: { xs: 0, sm: '3rem' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minWidth: { xs: '100%', sm: '450px' },
        maxWidth: '100vw',
        padding: { xs: '3rem', sm: '0' },
      }}
    >
      <SectionImg aria-hidden="true" img={img} desc={imgDesc} />
      <Typography
        variant="h1"
        sx={{
          mt: 2,
          mb: 2,
          fontSize: '1.5rem',
          lineHeight: '2.5rem',
          letterSpacing: '0.25px',
          textTransform: 'titlecase',
          fontWeight: 'normal',
        }}
      >
        {title}
      </Typography>
      <Box component="section" sx={{ textAlign: 'center' }}>
        <Typography
          variant="h6"
          sx={{
            lineHeight: '1.5rem',
            letterSpacing: '0.15px',
            fontSize: '1rem',
            textAlign: 'center',
            mt: 2,
            mb: 2,
            alignSelf: 'stretch',
            width: 'auto',
          }}
        >
          {descText}
        </Typography>
        {descText2 ? (
          <Typography
            variant="h6"
            sx={{
              lineHeight: '1.5rem',
              letterSpacing: '0.15px',
              fontSize: '1rem',
              textAlign: 'center',
              mt: 2,
              mb: 2,
              alignSelf: 'stretch',
              width: 'auto',
            }}
          >
            {descText2}
          </Typography>
        ) : null}
      </Box>
      {buttonText ? (
        <Button
          component={Link}
          to={path}
          variant="contained"
          onClick={onClick || null}
          sx={{
            bgcolor: 'primary.main',
            mt: 3,
            width: { xs: '100%', sm: '279px' },
            textTransform: 'capitalize',
          }}
        >
          {buttonText}
        </Button>
      ) : null}
      {displayContactMessage ? (
        <Box mt={3}>
          <Typography sx={{ textAlign: 'center' }}>
            If you need assistance, please reach out to your
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>
            Account Executive or call us at{' '}
            <Link
              id="contactPhoneLink"
              sx={{
                textTransform: 'none',
                cursor: 'pointer',
                maxWidth: '64px',
              }}
              href="tel:+18009818898"
            >
              800-981-8898
            </Link>
            .
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};
