import { createSlice } from '@reduxjs/toolkit';

const initialState = { error: false, title: null, message: null };

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError(state, action) {
      const { error, title, message } = action.payload;
      state.error = error;
      state.title = title;
      state.message = message;
    },
    clearError: () => initialState,
  },
});

export const { setError, clearError } = errorSlice.actions;
export default errorSlice.reducer;
