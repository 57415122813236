import { apiEndPoints } from 'common/constants/apiEndpoints';
import { baseApi } from './baseApi';

const userDataApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    loginStart: builder.mutation({
      query: () => ({
        url: apiEndPoints.LOGIN_START,
        method: 'POST',
      }),
      transformResponse: (response) => {
        const url = response.authorizationRequestUrl;
        window.location.replace(url);
      },
      invalidatesTags: (error) => (error ? ['UserProfile'] : []),
    }),
    loginEnd: builder.mutation({
      query: (pageUrl) => ({
        url: apiEndPoints.LOGIN_END,
        method: 'POST',
        body: JSON.stringify({
          pageUrl,
        }),
      }),
      transformResponse: async (response) => {
        const csrf = response.csrf;
        sessionStorage.setItem('x-disclosure-settings-csrf', csrf);
        return response;
      },
    }),
    getUserProfile: builder.query({
      query: (pageUrl) => ({
        url: apiEndPoints.GET_USER_PROFILE,
        method: 'POST',
        body: JSON.stringify({
          pageUrl,
        }),
      }),
      providesTags: ['UserProfile'],
    }),
    refresh: builder.mutation({
      query: (csrf) => ({
        url: apiEndPoints.REFRESH_TOKEN,
        method: 'POST',
        headers: {
          'x-disclosure-settings-csrf': csrf,
        },
      }),
    }),
    isUserAdmin: builder.query({
      query: (pageUrl) => ({
        url: apiEndPoints.IS_USER_ADMIN,
        method: 'POST',
        body: JSON.stringify({
          pageUrl,
        }),
      }),
      provideTags: ['IsUserAdmin'],
    }),
    isAuthLO: builder.query({
      query: (pageUrl) => ({
        url: apiEndPoints.IS_AUTH_LO,
        method: 'POST',
        body: JSON.stringify({
          pageUrl,
        }),
      }),
      provideTags: ['IsAuthLO'],
    }),
    logoutUser: builder.mutation({
      query: (csrf) => ({
        url: apiEndPoints.LOGOUT,
        method: 'POST',
        headers: {
          'x-disclosure-settings-csrf': csrf,
        },
      }),
      invalidatesTags: (error) => (error ? [] : ['UserProfile']),
      transformResponse: async (response) => {
        sessionStorage.removeItem('x-disclosure-settings-csrf');
        sessionStorage.removeItem('sessionStart');
      },
    }),
    overrideExisting: false,
  }),
});

export const {
  useGetUserProfileQuery,
  useIsUserAdminQuery,
  useIsAuthLOQuery,
  useRefreshMutation,
  useLoginStartMutation,
  useLoginEndMutation,
  useLogoutUserMutation,
} = userDataApi;
