import { Box, Typography, FormHelperText } from '@mui/material';

export const Titles = ({ title, subTitle }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Typography variant="h4" align="center" mt={2} pb={1}>
      {title}
    </Typography>
    {subTitle ? (
      <Box
        sx={{
          width: '300px',
          m: 'auto',
          pb: 1,
          textAlign: 'center',
        }}
      >
        {subTitle && (
          <FormHelperText sx={{ textAlign: 'center', fontSize: '14px' }}>
            {subTitle}
          </FormHelperText>
        )}
      </Box>
    ) : null}
  </Box>
);
