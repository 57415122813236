import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  useGetUserProfileQuery,
  useIsAuthLOQuery,
} from 'redux/services/userApi';
import { useIsUserAdminQuery } from 'redux/services/userApi';
import { setUser, setUserIsAuthLO } from 'redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { setUserAdmin } from 'redux/slices/userSlice';

export const useGetUserInfo = (isLoggedIn) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: userInfo,
    isFetching: isFetchingUserInfo,
    isError: isErrorUserInfo,
  } = useGetUserProfileQuery(undefined, { skip: !isLoggedIn });

  const {
    data: adminUser,
    isFetching: isFetchingIsUserAdmin,
    isError: isErrorUserAdmin,
  } = useIsUserAdminQuery(undefined, { skip: !isLoggedIn || !userInfo });

  const {
    data: authLOUser,
    isFetching: isFetchingIsUserAuthLO,
    isError: isErrorUserAuthLO,
  } = useIsAuthLOQuery(undefined, { skip: !isLoggedIn || !userInfo });

  useEffect(() => {
    isErrorUserInfo && navigate('/oops');
  }, [isErrorUserInfo]);

  useEffect(() => {
    if (!isFetchingIsUserAdmin && !isErrorUserAdmin) {
      dispatch(setUserAdmin(adminUser));
    }
  }, [userInfo, adminUser, isFetchingIsUserAdmin, isErrorUserAdmin]);

  useEffect(() => {
    if (!isFetchingIsUserAuthLO && !isErrorUserAuthLO) {
      dispatch(setUserIsAuthLO(authLOUser));
    }
  }, [authLOUser, isFetchingIsUserAuthLO]);

  useEffect(() => {
    !isFetchingUserInfo && !isErrorUserInfo && dispatch(setUser(userInfo));
  }, [userInfo, isErrorUserInfo, isFetchingUserInfo]);

  return {
    userInfo,
    isFetchingUserInfo,
    adminUser,
    isFetchingIsUserAdmin,
    authLOUser,
    isFetchingIsUserAuthLO,
  };
};
