import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  availableStates: [],
  savedDisclosures: [],
};

export const disclosureSlice = createSlice({
  name: 'disclosures',
  initialState,
  reducers: {
    setAvailableStates: (state, { payload }) => {
      state.availableStates = payload;
    },
    setSavedDisclosures: (state, { payload }) => {
      state.savedDisclosures = payload;
    },
    setShouldResetFields: (state, { payload }) => {
      state.shouldResetFields = payload;
    },
  },
});

export const { setAvailableStates, setSavedDisclosures } =
  disclosureSlice.actions;
export default disclosureSlice.reducer;
