import { configureStore } from '@reduxjs/toolkit';
import modeReducer from './slices/modeSlice';
import userReducer from './slices/userSlice';
import disclosuresReducer from './slices/disclosuresSlice';
import successReducer from './slices/successSlice';
import errorReducer from './slices/errorSlice';
import { baseApi } from './services/baseApi';
import { rtkQueryErrorLogger } from './services/rtkQueryErrorLogger';

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    mode: modeReducer,
    user: userReducer,
    disclosures: disclosuresReducer,
    error: errorReducer,
    success: successReducer,
    [baseApi.reducerPath]: baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseApi.middleware)
      .concat(rtkQueryErrorLogger),
});

export default store;
