import { apiEndPoints } from 'common/constants/apiEndpoints';
import { baseApi } from './baseApi';

const disclosuresApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStateDisclosures: builder.query({
      query: (data) => ({
        url: apiEndPoints.GET_STATE_DISCLOSURES,
        method: 'POST',
        body: data,
      }),
      providesTags: ['DisclosuresAnswers'],
      transformErrorResponse: (response) => {
        const errorResponse = response;
        errorResponse.customErrorMessage =
          'There was an error retrieving your information. Please try again at another time.';
        return errorResponse;
      },
    }),
    saveDisclosureDocumentAnswers: builder.mutation({
      query: (data) => ({
        url: apiEndPoints.SAVE_DISCLOSURE_DOCUMENT_ANSWERS,
        method: 'POST',
        body: data,
      }),
      transformErrorResponse: (response) => {
        const errorResponse = response;
        errorResponse.customErrorMessage =
          'There was an error saving your information. Please try again at another time.';
        return errorResponse;
      },
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        setTimeout(() => {
          dispatch(
            disclosuresApi.util.invalidateTags(
              result?.data?.success ? ['DisclosuresAnswers'] : [],
            ),
          );
        }, 2000);
      },
    }),
  }),
});

export const {
  useGetStateDisclosuresQuery,
  useSaveDisclosureDocumentAnswersMutation,
} = disclosuresApi;
