import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import { clearSuccess } from 'redux/slices/successSlice';

export const Success = ({ category }) => {
  const dispatch = useDispatch();
  const { success, title, message } = useSelector(({ success }) => success);

  const handleClose = () => {
    dispatch(clearSuccess());
  };

  return (
    <Snackbar
      open={success}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{ marginBottom: '55px' }}
    >
      <Alert
        onClose={handleClose}
        severity="success"
        sx={{
          alignItems: 'flex-start',
          border: 'solid green 1px',
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
