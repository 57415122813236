import { Radio, TextArea, Dropdown, Checkbox } from '../Form';
import { InputLabel } from '@mui/material';
import { useTheme } from '@emotion/react';
import { forwardRef } from 'react';
import { getPossibleAnswers } from 'common/utils';
import { Stack } from '@mui/system';

export const Question = forwardRef((props, ref) => {
  const { palette } = useTheme();

  let displayQuestion;

  const possibleAnswers = getPossibleAnswers(props?.question);

  switch (props?.question?.displayType) {
    case 'TextArea':
      displayQuestion = <TextArea {...props} ref={ref} />;
      break;
    case 'Dropdown':
      displayQuestion = (
        <Dropdown {...props} options={possibleAnswers} ref={ref} />
      );
      break;
    case 'Checkbox':
      displayQuestion = <Checkbox {...props} ref={ref} />;
      break;
    case 'Radio':
      displayQuestion = (
        <Radio {...props} options={possibleAnswers} ref={ref} />
      );
      break;
    default:
      break;
  }
  return (
    <Stack spacing={1} sx={{ my: 2 }}>
      <InputLabel
        sx={{
          color: palette?.text?.primary,
          marginBottom: 1,
          maxWidth: '420px',
          textWrap: 'wrap',
        }}
        data-test="question"
      >
        {props.question.questionName}
        <span style={{ color: 'red' }}>
          {props.question?.isRequired ? ' *' : ''}
        </span>
      </InputLabel>
      {displayQuestion}
    </Stack>
  );
});
