import { Button } from '@mui/material';

export const SubmitButton = ({ children, loading = false, ...props }) => {
  return (
    <Button
      type="submit"
      variant="contained"
      {...props}
      sx={{
        fontSize: '15px',
        zIndex: 1,
        margin: 1,
        textTransform: 'none',
        pointerEvents: loading ? 'none' : 'auto',
      }}
    >
      {children}
    </Button>
  );
};
