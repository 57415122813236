import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

import { LicenseInfo } from '@mui/x-data-grid-pro';

import '@fontsource/roboto/300.css';

import '@fontsource/roboto/400.css';

import '@fontsource/roboto/500.css';

import '@fontsource/roboto/700.css';

import store from './redux/store';
import App from './App';

/* If your application doesn't use MUI X Data Grid Pro you should remove the mui-x-license below. */
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_KEY);

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </React.StrictMode>,
);
