import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NoAccess } from 'pages/NoAccess';
import { DisclosuresView } from 'pages/Disclosures';
import AuthRoutes from 'common/utils/authGuard/AuthRoutes';
import MainLayout from 'common/layouts/MainLayout';
import { Oops } from 'pages/Oops';
import { SessionTimeout } from 'pages/SessionTimeout';
const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/no-access" element={<NoAccess />} />
        <Route path="/oops" element={<Oops />} />
        <Route path="/session-ended" element={<SessionTimeout />} />

        <Route element={<AuthRoutes />}>
          <Route path="*" element={<DisclosuresView />} />
          <Route exact path="/" element={<DisclosuresView />} />
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
