import { uwmLightTheme, uwmDarkTheme } from 'lib-node-uwm-theme';

// Colors

// DARK
uwmDarkTheme.palette.gray[350] = 'rgba(0, 0, 0, 0.6)';

uwmDarkTheme.palette.backgroundColor = 'rgba(18,18,18,1)';

uwmDarkTheme.palette.darkBorder = '1px solid rgba(255,255,255,0.23)';
uwmDarkTheme.palette.thinDarkBorder = '1px solid rgba(255,255,255,0.12)';

uwmDarkTheme.palette.primaryHover = 'rgba(247,169,116,0.04)';
uwmDarkTheme.palette.primaryActive = 'rgba(255,255,255,0.08)';

// LIGHT
uwmLightTheme.palette.gray[350] = 'rgba(0, 0, 0, 0.6)';

uwmLightTheme.palette.backgroundColor = 'rgba(255,255,255,1)';

uwmLightTheme.palette.lightBorder = '1px solid rgba(0,0,0,0.23)';
uwmLightTheme.palette.thinLightBorder = '1px solid rgba(0,0,0,0.12)';

uwmLightTheme.palette.primaryHover = 'rgba(8,86,139,0.04)';
uwmLightTheme.palette.primaryActive = 'rgba(0,0,0,0.04)';

const themes = { uwmLightTheme, uwmDarkTheme };

export default themes;
