import { Box, Stack } from '@mui/system';
import Header from './Header/Header';
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
  return (
    <>
      <Header />
      <Box
        id="mainContainer"
        as="section"
        sx={{
          p: '40px',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Stack
          spacing={4}
          sx={{
            minWidth: '700px',
            maxWidth: '1200px',
            flex: 'auto',
          }}
        >
          <Outlet />
        </Stack>
      </Box>
    </>
  );
};

export default MainLayout;
