import { Box } from '@mui/material';

export const DisplayImage = ({ img, description }) => (
  <Box
    component="img"
    alt={description}
    src={img}
    sx={{ display: 'block', m: 'auto' }}
  />
);
