import { Typography, Box } from '@mui/material';

import { Document } from 'common/components/Document/Document';
import { useGetSavedDisclosures } from 'hooks/useGetSavedDisclosures';

export const StateSelected = ({ selectedState }) => {
  const { savedDisclosures } = useGetSavedDisclosures();
  let selectedStateDisclosures = savedDisclosures?.stateDisclosures?.find(
    (state) => state.state === selectedState,
  )?.categories;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography sx={{ fontSize: '24px', marginBottom: '2rem' }}>
        {selectedState}
      </Typography>
      {selectedStateDisclosures?.map((doc, index) => (
        <Document
          key={`${selectedState}Doc${index}`}
          doc={doc}
          stateName={selectedState}
          index={index}
          isExpanded={selectedStateDisclosures.length === 1 || false}
        />
      ))}
    </Box>
  );
};
