import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import {
  Checkbox as MuiCheckbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

export const Checkbox = forwardRef((props, ref) => {
  return (
    <FormControl error={props.error} sx={{ padding: '8px 0' }}>
      <Controller
        name={props.name}
        control={props.control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            label={props.label}
            disabled={props.disabled}
            control={
              <MuiCheckbox
                id={props.id}
                inputRef={ref}
                checked={!!value}
                onChange={(e) => onChange(e.target.checked)}
              />
            }
          />
        )}
      />
      {props.error && <FormHelperText error>{props.message}</FormHelperText>}
    </FormControl>
  );
});
