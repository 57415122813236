import { memo } from 'react';
import { Stack } from '@mui/material';
import UWM_logo from 'assets/UWM.svg';
import UWM_Header_logo from 'assets/UWM_logo.svg';

export const UWMLogo = memo(() => (
  <Stack mb={2} mt={2} alignItems="center">
    <img
      src={UWM_logo}
      alt="UWM Logo"
      style={{
        width: '150px',
        height: '80px',
        display: 'flex',
      }}
    />
  </Stack>
));

export const HeaderLogo = memo(() => (
  <img
    src={UWM_Header_logo}
    alt="UWM Logo"
    style={{ height: '24px', display: 'flex' }}
  />
));
