import noAccessImg from 'assets/noAccessImg.png';
import { PageTemplate } from 'common/components/PageTemplate';

export const NoAccess = () => {
  return (
    <PageTemplate
      title={"Can't Access Disclosure Settings"}
      descText={'You do not have permission to access Disclosure Settings.'}
      img={noAccessImg}
      imgDesc={'image for access denied page'}
      displayContactMessage={false}
    />
  );
};
