import { Stack } from '@mui/material';
import NoSelectedState from 'assets/noSelectedState.svg';
import { DisplayImage, Titles } from 'common/components';

export const NoStateSelected = () => {
  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pt: '147px',
        pb: '24px',
        minWidth: '178px',
        minHeight: '98px',
      }}
    >
      <DisplayImage
        img={NoSelectedState}
        description="No selected state image."
      />
      <Titles
        title="Change Settings"
        subTitle="Select a state to update disclosure settings."
      />
    </Stack>
  );
};
