import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import svgLoader from 'assets/loader.svg';
import svgLoaderWhite from 'assets/loaderWhite.svg';
import { SvgImage } from './SvgImage';

const animateRotation = (element, ref) => {
  let start = 0;

  const recursiveCall = () => {
    element.style.transform = `rotate(${start}deg)`;
    start += 8;
    ref.id = requestAnimationFrame(recursiveCall);
  };
  return recursiveCall;
};

export const CustomLoader = ({ style, isWhite }) => {
  const [currentAnimationId, setCurrentAnimationId] = useState('');
  const loaderRef = useRef();
  useEffect(() => {
    let animation;
    if (loaderRef.current) {
      loaderRef.counter = 0;
      loaderRef.id = requestAnimationFrame(
        animateRotation(loaderRef.current, loaderRef),
      );
      setCurrentAnimationId(() => animation);
    }
    return () => {
      cancelAnimationFrame(loaderRef.id);
    };
  }, [loaderRef, currentAnimationId]);

  return (
    <Box>
      {isWhite ? (
        <SvgImage style={style} src={svgLoaderWhite} ref={loaderRef} />
      ) : (
        <SvgImage style={style} src={svgLoader} ref={loaderRef} />
      )}
    </Box>
  );
};
