import { useDispatch, useSelector } from 'react-redux';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import { clearError } from 'redux/slices/errorSlice';

export const Error = () => {
  const dispatch = useDispatch();
  const { error, title, message } = useSelector(({ error }) => error);

  const handleClose = () => {
    dispatch(clearError());
  };

  return (
    <Snackbar
      open={error}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{ marginBottom: '55px' }}
    >
      <Alert
        onClose={handleClose}
        severity="error"
        sx={{
          alignItems: 'flex-start',
          border: 'solid red 1px',
        }}
      >
        {title ? (
          <AlertTitle>{title}</AlertTitle>
        ) : (
          <AlertTitle>Looks like we have a connection issue.</AlertTitle>
        )}
        {message
          ? message
          : 'There was an error saving your information. Please try again at another time.'}
      </Alert>
    </Snackbar>
  );
};
