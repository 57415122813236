import { useEffect } from 'react';
import { useLoginStartMutation } from '../../../redux/services/userApi';
import { Outlet } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { LoadingBackdrop } from './LoadingBackdrop';
import useAuthentication from 'hooks/useAuthentication';
import { useNavigate } from 'react-router-dom';
import { useSessionTimeoutAndRefresh } from 'hooks/useSessionTimeoutAndRefresh';
import { useLogoutUserMutation } from '../../../redux/services/userApi';
import { useGetUserInfo } from 'hooks/useGetUserInfo';
import { useDispatch } from 'react-redux';
import { setLoginStatus } from 'redux/slices/userSlice';

const AuthRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoggedIn, isLoading } = useAuthentication();
  const [loginStart] = useLoginStartMutation();
  const { userInfo, isFetchingGetUserInfo } = useGetUserInfo(isLoggedIn);
  const inactive = useSessionTimeoutAndRefresh();
  const [logoutUser] = useLogoutUserMutation();
  const csrf = sessionStorage.getItem('x-disclosure-settings-csrf');
  const sessionStart = sessionStorage.getItem('sessionStart');

  useEffect(() => {
    isLoggedIn && dispatch(setLoginStatus(isLoggedIn));

    if (isLoggedIn === false && !isLoading) {
      loginStart();
    }
    isLoggedIn &&
      !sessionStart &&
      sessionStorage.setItem('sessionStart', new Date().getTime());
  }, [isLoggedIn, loginStart]);

  useEffect(() => {
    !isFetchingGetUserInfo &&
      userInfo &&
      !userInfo?.userId &&
      navigate('/access-denied');
  });

  useEffect(() => {
    if (inactive) {
      logoutUser(csrf);

      navigate('/session-ended');
    }
  }, [inactive]);

  if (isLoading) {
    return (
      <Box
        sx={{
          marginBottom: { xs: '12rem', md: '4rem' },
          marginTop: '8rem',
        }}
      >
        <LoadingBackdrop />
      </Box>
    );
  } else if (!isLoading && isLoggedIn) return <Outlet />;

  if (isLoading) {
    return (
      <Box
        sx={{
          marginBottom: { xs: '12rem', md: '4rem' },
          marginTop: '8rem',
        }}
      >
        <LoadingBackdrop />
      </Box>
    );
  } else if (!isLoading && isLoggedIn) return <Outlet />;

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        pt: '147px',
        pb: '24px',
        minWidth: '178px',
        minHeight: '98px',
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            marginBottom: { xs: '12rem', md: '4rem' },
            marginTop: '8rem',
          }}
        >
          <LoadingBackdrop />
        </Box>
      ) : (
        <>{!isFetchingGetUserInfo && isLoggedIn && <Outlet />}</>
      )}
    </Stack>
  );
};

export default AuthRoutes;
