export const getPossibleAnswers = (question) => {
  return question?.possibleAnswers?.map((answer) => {
    return { label: answer, value: answer };
  });
};

export const clearForm = (questions) => {
  let clearedQuestions = JSON.parse(JSON.stringify([...questions]));
  clearedQuestions = clearedQuestions
    ?.map((question) => ({
      ...question,
      answer: '',
    }))
    .map((question) => ({
      ...question,
      childQuestions: question.childQuestions?.map((childQuestion) => ({
        ...childQuestion,
        answer: '',
        childQuestions: childQuestion.childQuestions?.map(
          (nestedChildQuestion) => ({ ...nestedChildQuestion, answer: '' }),
        ),
      })),
    }));

  return clearedQuestions;
};

export const getQuestionValidations = (question) => {
  let validations = {};
  switch (question?.possibleAnswers?.[0]) {
    case '{{PhoneNumber}}':
      validations = {
        required: {
          value: question?.isRequired,
          message: `This field is required.`,
        },
        minLength: {
          value: 10,
          message: 'Please enter a valid phone number.',
        },
      };
      break;
    default:
      validations = {
        required: {
          value: question?.isRequired,
          message: `This field is required.`,
        },
      };
      break;
  }
  return validations;
};
