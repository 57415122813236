import { Box, Typography, Alert } from '@mui/material';
import StateMenu from 'common/components/StateMenu/StateMenu';
import { StateDocuments } from 'common/components/StateDocuments/StateDocuments';
import { useState, useEffect } from 'react';
import { useGetSavedDisclosures } from 'hooks/useGetSavedDisclosures';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LoadingBackdrop } from 'common/utils/authGuard/LoadingBackdrop';

export const DisclosuresView = () => {
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState('');
  const isAdmin = useSelector(({ user }) => user.isAdmin);
  const isAuthLO = useSelector(({ user }) => user.isAuthLO);
  const {
    isFetchingSavedDisclosures,
    savedDisclosures,
    savedDisclosuresError,
  } = useGetSavedDisclosures();
  const handleSelectedState = (state) => {
    setSelectedState(state);
  };

  useEffect(() => {
    savedDisclosuresError && navigate('/oops');
  }, [savedDisclosuresError]);

  const isAuthLOFromCalifornia =
    isAuthLO && selectedState === 'California (Loan Officers)';

  return (
    <>
      {!savedDisclosures && isFetchingSavedDisclosures ? (
        <>
          <LoadingBackdrop />
        </>
      ) : (
        <Box>
          <>
            {savedDisclosures ? (
              <>
                {isAdmin || isAuthLOFromCalifornia ? (
                  <Typography sx={{ fontWeight: '400', fontSize: '16px' }}>
                    You are free to make changes that affect your organization.
                    Disclosure settings are automatically applied to EASE Docs
                    when saved.
                  </Typography>
                ) : (
                  <>
                    <Alert
                      severity="info"
                      sx={{
                        alignItems: 'flex-start',
                        border: 'solid blue 1px',
                      }}
                    >
                      You do not have permission to make preference changes.
                      Contact your administrator to request desired changes.
                    </Alert>
                    <br />
                    <Typography sx={{ fontWeight: '400', fontSize: '16px' }}>
                      Saved disclosure settings are automatically applied in
                      EASE Docs.
                    </Typography>
                  </>
                )}
                <Box
                  spacing={4}
                  mt={2}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    height: 'fit-content',
                  }}
                >
                  <StateMenu handleSelectedState={handleSelectedState} />
                  <StateDocuments
                    selectedState={selectedState}
                    isFetchingSavedDisclosures={isFetchingSavedDisclosures}
                  />
                </Box>
              </>
            ) : null}
          </>
        </Box>
      )}
    </>
  );
};
