import { useSelector } from 'react-redux';
import { Stack, Box } from '@mui/system';
import { NoStateSelected, StateSelected } from 'pages/Disclosures';
import { useTheme } from '@emotion/react';
import { CustomLoader } from '../CustomLoader';

export const StateDocuments = ({
  selectedState,
  isFetchingSavedDisclosures,
}) => {
  const { palette } = useTheme();
  const mode = useSelector((mode) => mode);

  return (
    <Stack
      sx={{
        border: mode === 'light' ? palette.lightBorder : palette.darkBorder,
        borderRadius: '6px',
        minHeight: '780px',
        display: 'flex',
        flexDirection: 'column',
        flex: 'auto',
        flexGrow: 'grow',
        backgroundColor: palette.backgroundColor,
        padding: '1rem 1.5rem',
      }}
      spacing={2}
    >
      {isFetchingSavedDisclosures !== false ? (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CustomLoader />
        </Box>
      ) : !!selectedState ? (
        <StateSelected selectedState={selectedState} />
      ) : (
        <NoStateSelected />
      )}
    </Stack>
  );
};
