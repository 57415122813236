import { Box } from '@mui/material';
import { forwardRef } from 'react';

export const SvgImage = forwardRef(({ alt, src, style, isSvg }, ref) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
      as="img"
      src={src}
      alt={alt}
      ref={ref}
    />
  );
});
