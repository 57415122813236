import { useSelector } from 'react-redux';
import { Box, Stack } from '@mui/system';
import React from 'react';
import { useTheme } from '@emotion/react';
import { List, ListItemButton, Typography, Divider } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import themes from 'setupTheme';

const StateMenu = ({ handleSelectedState }) => {
  const [selectedIndex, setSelectedIndex] = React.useState();
  const { palette } = useTheme();
  const mode = useSelector((mode) => mode);
  const availableStates = useSelector(
    ({ disclosures }) => disclosures.availableStates,
  );
  const handleListItemClick = (index, state) => {
    setSelectedIndex(index);
    handleSelectedState(state);
  };

  return (
    <Stack
      spacing={0}
      sx={{
        border: mode === 'light' ? palette.lightBorder : palette.darkBorder,
        borderRadius: '6px',
        minHeight: '780px',
        mr: '16px',
        minWidth: '324px',
        backgroundColor: palette.backgroundColor,
      }}
    >
      {!!availableStates ? (
        <List sx={{ padding: 0 }}>
          {availableStates?.map((state, index) => {
            return (
              <Box id={`listItemContainer${state}`} key={`listItem${state}`}>
                <ListItemButton
                  sx={{
                    py: 2,
                    pl: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  selected={selectedIndex === index}
                  onClick={() => handleListItemClick(index, state)}
                  key={index}
                  id={`listItemBtn${state}`}
                  name={state}
                >
                  <Typography
                    id={`listItemTxt${state}`}
                    key={`listItemTxt${state}`}
                    sx={{ fontWeight: selectedIndex === index ? 600 : '' }}
                  >
                    {state}
                  </Typography>
                </ListItemButton>
                <Divider key={`listItemDivider${index}`} light />
              </Box>
            );
          })}
        </List>
      ) : (
        <Typography>No States Available</Typography>
      )}
    </Stack>
  );
};

export default StateMenu;
