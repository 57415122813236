import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Box } from '@mui/material';
import { Category, DisplayForm } from '../Form';

export const Document = ({
  doc,
  stateName,
  index,
  isExpanded: _isExpanded,
}) => {
  const { contactId, entityId, brokerId } = useSelector(
    ({ user }) => user.userData,
  );
  const [isExpanded, setIsExpanded] = useState(_isExpanded);

  return (
    <Box sx={{ padding: '0.5rem 1rem' }} id="documentContainer">
      <Category
        id="documentCategory"
        category={doc?.categoryName}
        handleChange={() => setIsExpanded(() => !isExpanded)}
        isExpanded={isExpanded}
        index={index}
      >
        <Stack spacing={5}>
          <DisplayForm
            category={doc?.categoryName}
            stateQuestions={doc?.questions}
            contactId={contactId}
            entityId={entityId}
            brokerId={brokerId}
            stateName={stateName}
          />
        </Stack>
      </Category>
    </Box>
  );
};
