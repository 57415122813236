export const returnFormattedQuestions = (questions) => {
  const level1QuestionsArray = questions?.map((question) => {
    return {
      question: question.description,
      answer: question.answer,
    };
  });

  const level2QuestionsArray = questions
    ?.map((question) => {
      return question?.childQuestions?.map((childQuestion1) => {
        if (!childQuestion1.childDisplayAnswer.includes(question.answer)) {
          return {
            question: childQuestion1.description,
            answer: null,
          };
        }
        return {
          question: childQuestion1.description,
          answer: childQuestion1.answer,
        };
      });
    })
    .flat();

  const level3QuestionsArray = questions
    ?.map((question) => {
      return question?.childQuestions
        ?.map((childQuestion1) => {
          return childQuestion1.childQuestions.map((childQuestion2) => {
            if (
              !childQuestion1.childDisplayAnswer.includes(question.answer) ||
              !childQuestion2.childDisplayAnswer.includes(childQuestion1.answer)
            ) {
              return {
                question: childQuestion2.description,
                answer: null,
              };
            }
            return {
              question: childQuestion2.description,
              answer: childQuestion2.answer,
            };
          });
        })
        .flat();
    })
    .flat();

  const level4QuestionsArray = questions
    ?.map((question) => {
      return question?.childQuestions
        ?.map((childQuestion1) => {
          return childQuestion1.childQuestions
            .map((childQuestion2) => {
              return childQuestion2.childQuestions.map((childQuestion3) => {
                if (
                  !childQuestion1.childDisplayAnswer.includes(
                    question.answer,
                  ) ||
                  !childQuestion2.childDisplayAnswer.includes(
                    childQuestion1.answer,
                  ) ||
                  !childQuestion3.childDisplayAnswer.includes(
                    childQuestion2.answer,
                  )
                ) {
                  return {
                    question: childQuestion3.description,
                    answer: null,
                  };
                }
                return {
                  question: childQuestion3.description,
                  answer: childQuestion3.answer,
                };
              });
            })
            .flat();
        })
        .flat();
    })
    .flat();
  const formattedQuestions = [
    ...level1QuestionsArray,
    ...level2QuestionsArray,
    ...level3QuestionsArray,
    ...level4QuestionsArray,
  ];

  return formattedQuestions;
};
