import { Box } from '@mui/material';

export const Form = ({ children, ...props }) => {
  return (
    <Box sx={{ m: 'auto' }}>
      <form {...props} noValidate>
        {children}
      </form>
    </Box>
  );
};
