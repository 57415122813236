import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  setAvailableStates,
  setSavedDisclosures,
} from '../redux/slices/disclosuresSlice';
import { useGetStateDisclosuresQuery } from 'redux/services/disclosuresApi';
import { useNavigate } from 'react-router-dom';

export const useGetSavedDisclosures = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contactId = parseInt(
    useSelector(({ user }) => user?.userData?.contactId),
  );
  const entityId = parseInt(
    useSelector(({ user }) => user?.userData?.entityId),
  );
  const brokerId = useSelector(({ user }) => user?.userData?.brokerId);

  const {
    data: savedDisclosures,
    isFetching: isFetchingSavedDisclosures,
    isError: savedDisclosuresError,
  } = useGetStateDisclosuresQuery(
    {
      homeOfficeBrokerid: brokerId,
      entityId: entityId,
      contactId: contactId,
    },
    { skip: !entityId || !contactId },
  );

  const stateDisclosures = savedDisclosures?.stateDisclosures;

  useEffect(() => {
    if (savedDisclosures) {
      let availableStates = stateDisclosures?.map(function (disclosure) {
        return disclosure.state;
      });
      availableStates
        ?.sort()
        .unshift(
          availableStates?.splice(
            availableStates?.indexOf('Privacy Form No OptOut'),
            1,
          )[0],
        );
      dispatch(setAvailableStates(availableStates));
      dispatch(setSavedDisclosures(savedDisclosures));
    }
  }, [stateDisclosures, savedDisclosures]);

  useEffect(() => {
    !isFetchingSavedDisclosures && savedDisclosuresError && navigate('/oops');
  }, [savedDisclosures, isFetchingSavedDisclosures, savedDisclosuresError]);

  return {
    savedDisclosures,
    isFetchingSavedDisclosures,
    savedDisclosuresError,
  };
};
