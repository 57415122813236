import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio as MuiRadio,
  RadioGroup as RadioGroupMUI,
  Link,
} from '@mui/material';

export const Radio = forwardRef(
  (
    {
      question,
      name,
      control,
      id,
      inputProps,
      helpertext,
      error,
      type,
      label,
      link,
      subLabel,
      href,
      options,
      ...props
    },
    ref,
  ) => {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={''}
        rules={{
          required: { value: true, message: 'Required field.' },
        }}
        render={({ field: { onChange, value } }) => (
          <FormControl
            variant="outlined"
            fullWidth
            margin="normal"
            sx={{ marginTop: 0 }}
          >
            {/* <Typography variant="subtitle1">{label}</Typography> */}
            <FormHelperText>{subLabel ?? ''}</FormHelperText>
            <FormHelperText>
              <Link
                underline="none"
                href={href ?? ''}
                target="_blank"
                sx={{ textDecoration: 'none' }}
              >
                {link ?? ''}
              </Link>
            </FormHelperText>
            <RadioGroupMUI
              sx={{ marginLeft: '0.5rem', marginTop: 0 }}
              name={name}
              value={value}
              onChange={onChange}
              {...props}
            >
              {options?.map((option, index) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  disabled={props.disabled}
                  id={`${id}${index}`}
                  control={<MuiRadio inputRef={ref} />}
                >
                  {option.label}
                </FormControlLabel>
              ))}
            </RadioGroupMUI>
            {error && <FormHelperText error>{helpertext}</FormHelperText>}
          </FormControl>
        )}
      />
    );
  },
);
