import { useForm } from 'react-hook-form';
import { Form, SubmitButton, Question } from '.';
import { Box } from '@mui/system';
import { Stack, Typography, useTheme } from '@mui/material';
import { returnFormattedQuestions } from 'common/utils/formatters';
import { useSaveDisclosureDocumentAnswersMutation } from 'redux/services/disclosuresApi';
import { useEffect } from 'react';
import { setSuccess } from 'redux/slices/successSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionValidations } from 'common/utils';
import { Spinner } from 'common/components/Spinner';

//TODO - clean this up for readability (way too confusing to look at)
import { setError } from 'redux/slices/errorSlice';

export const DisplayForm = ({
  stateQuestions,
  entityId,
  contactId,
  brokerId,
  stateName,
  category,
}) => {
  let defaultValues = {
    entityId: entityId,
    brokerId: brokerId,
    contactId: contactId,
    stateName: stateName,
    questions: [...stateQuestions],
  };

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    control,
    watch,
  } = useForm({
    defaultValues: {
      ...defaultValues,
    },
    mode: 'onSubmit',
    shouldFocusError: true,
  });
  const { palette } = useTheme();
  const adminUser = useSelector((state) => state.user.isAdmin);
  const isAuthLO = useSelector((state) => state.user.isAuthLO);
  const dispatch = useDispatch();
  const watchAllQuestions = watch();
  const [
    saveDisclosureDocumentAnswers,
    {
      isLoading: saveDisclosureAnswersIsLoading,
      isError: saveDisclosureAnswersError,
    },
  ] = useSaveDisclosureDocumentAnswersMutation();

  useEffect(() => {
    reset({
      ...defaultValues,
    });
  }, [reset]);

  const isAuthLOFromCalifornia =
    isAuthLO && stateName === 'California (Loan Officers)';
  const isDisabled = () => {
    if (adminUser || isAuthLOFromCalifornia) {
      return false;
    } else {
      return true;
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const formattedData = {
      homeOfficeBrokerId: data.brokerId,
      entityId: parseInt(data.entityId),
      contactId: parseInt(data.contactId),
      state: data.stateName,
      disclosureAnswers: returnFormattedQuestions(data.questions),
    };
    if (isDirty) {
      const documentSaved = await saveDisclosureDocumentAnswers(formattedData);
      if (!documentSaved.error) {
        if (documentSaved?.data?.success) {
          dispatch(
            setSuccess({
              success: true,
              title: '',
              message: `${category} saved successfully.`,
            }),
          );
        } else {
          dispatch(
            setError({
              error: true,
              title: 'Looks like we have a connection issue.',
              message: `There was an error saving ${category}. Please try again at another time.`,
            }),
          );
        }
      } else {
        dispatch(
          setError({
            error: true,
            title: 'Looks like we have a connection issue.',
            message: `There was an error saving ${category}. Please try again at another time.`,
          }),
        );
      }
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      ></Stack>
      <Form onSubmit={handleSubmit(onSubmit)} id="pageForm" data-test="form">
        {stateQuestions
          ? stateQuestions?.map((level1Question, index1) => {
              const level1QuestionIndex = `questions[${index1}]`;
              return (
                <Box
                  key={`level1Question-${index1}-container`}
                  sx={{ marginTop: '2.5rem', marginLeft: 0 }}
                >
                  <Question
                    {...register(
                      `${level1QuestionIndex}.answer`,
                      getQuestionValidations(level1Question),
                    )}
                    key={`level1Question-${index1}`}
                    data-test={level1Question.description}
                    question={level1Question}
                    name={`${level1QuestionIndex}.answer`}
                    control={control}
                    error={!!errors?.questions?.[index1]?.answer}
                    helpertext={errors?.questions?.[index1]?.answer?.message}
                    index={index1}
                    required={true}
                    disabled={isDisabled()}
                  ></Question>
                  {level1Question?.childQuestions
                    ? level1Question?.childQuestions?.map(
                        (level2Question, index2) => {
                          const displayLevel2Question =
                            watchAllQuestions?.questions?.[
                              index1
                            ].childQuestions?.[
                              index2
                            ].childDisplayAnswer.includes(
                              watchAllQuestions?.questions[index1].answer,
                            );
                          const level2QuestionIndex = `questions[${index1}].childQuestions[${index2}]`;
                          return (
                            <Box
                              key={`level2Question-${index2}-container`}
                              sx={{ marginTop: '2rem', marginLeft: '16px' }}
                              data-test={index2}
                            >
                              {displayLevel2Question ? (
                                <Question
                                  {...register(
                                    `${level2QuestionIndex}.answer`,
                                    getQuestionValidations(level2Question),
                                  )}
                                  key={`level2Question-${index2}`}
                                  data-test={level2Question.description}
                                  name={`${level2QuestionIndex}.answer`}
                                  question={level2Question}
                                  control={control}
                                  error={
                                    !!errors?.questions?.[index1]
                                      ?.childQuestions?.[index2]?.answer
                                  }
                                  helpertext={
                                    errors?.questions?.[index1]
                                      ?.childQuestions?.[index2]?.answer
                                      ?.message
                                  }
                                  index={index2}
                                  required={
                                    displayLevel2Question ? true : false
                                  }
                                  disabled={isDisabled()}
                                ></Question>
                              ) : null}
                              <Box>
                                {level2Question.childQuestions
                                  ? level2Question?.childQuestions?.map(
                                      (level3Question, index3) => {
                                        const displayLevel3Question =
                                          watchAllQuestions.questions[
                                            index1
                                          ].childQuestions[
                                            index2
                                          ].childQuestions[
                                            index3
                                          ].childDisplayAnswer.includes(
                                            watchAllQuestions.questions[index1]
                                              .childQuestions[index2].answer,
                                          ) && displayLevel2Question;
                                        return (
                                          <Box
                                            key={`level3Question-${index3}-container`}
                                            sx={{
                                              marginTop: '2rem',
                                              marginBottom: '3rem',
                                            }}
                                          >
                                            {displayLevel3Question ? (
                                              <Question
                                                {...register(
                                                  `questions[${index1}].childQuestions[${index2}].childQuestions[${index3}].answer`,
                                                  getQuestionValidations(
                                                    level3Question,
                                                  ),
                                                )}
                                                key={`level3Question-${index3}`}
                                                data-test={
                                                  level3Question.description
                                                }
                                                question={level3Question}
                                                name={`questions[${index1}].childQuestions[${index2}].childQuestions[${index3}].answer`}
                                                control={control}
                                                error={
                                                  !!errors?.questions?.[index1]
                                                    ?.childQuestions?.[index2]
                                                    ?.childQuestions?.[index3]
                                                    ?.answer
                                                }
                                                helpertext={
                                                  errors?.questions?.[index1]
                                                    ?.childQuestions?.[index2]
                                                    ?.childQuestions?.[index3]
                                                    ?.answer?.message
                                                }
                                                index={index3}
                                                required={
                                                  displayLevel3Question
                                                    ? true
                                                    : false
                                                }
                                                disabled={isDisabled()}
                                              ></Question>
                                            ) : null}
                                            <Box>
                                              {level3Question.childQuestions
                                                ? level3Question?.childQuestions?.map(
                                                    (
                                                      level4Question,
                                                      index4,
                                                    ) => {
                                                      const displayLevel4Question =
                                                        watchAllQuestions.questions[
                                                          index1
                                                        ].childQuestions[
                                                          index2
                                                        ].childQuestions[
                                                          index3
                                                        ].childQuestions[
                                                          index4
                                                        ].childDisplayAnswer.includes(
                                                          watchAllQuestions
                                                            .questions[index1]
                                                            .childQuestions[
                                                            index2
                                                          ].childQuestions[
                                                            index3
                                                          ].answer,
                                                        ) &&
                                                        displayLevel3Question;
                                                      const level4QuestionIndex = `questions[${index1}].childQuestions[${index2}].childQuestions[${index3}].childQuestions[${index4}]`;
                                                      return (
                                                        <Box
                                                          key={`level4Question-${index4}-container`}
                                                          sx={{
                                                            marginTop: '2rem',
                                                            marginBottom:
                                                              '3rem',
                                                          }}
                                                        >
                                                          {displayLevel4Question ? (
                                                            <Question
                                                              {...register(
                                                                `${level4QuestionIndex}.answer`,
                                                                getQuestionValidations(
                                                                  level4Question,
                                                                ),
                                                              )}
                                                              key={`level4Question-${index4}`}
                                                              question={
                                                                level4Question
                                                              }
                                                              name={`${level4QuestionIndex}.answer`}
                                                              data-test={
                                                                level4Question.description
                                                              }
                                                              control={control}
                                                              error={
                                                                !!errors
                                                                  ?.questions?.[
                                                                  index1
                                                                ]
                                                                  ?.childQuestions?.[
                                                                  index2
                                                                ]
                                                                  ?.childQuestions?.[
                                                                  index3
                                                                ]
                                                                  ?.childQuestions?.[
                                                                  index4
                                                                ]?.answer
                                                              }
                                                              helpertext={
                                                                errors
                                                                  ?.questions?.[
                                                                  index1
                                                                ]
                                                                  ?.childQuestions?.[
                                                                  index2
                                                                ]
                                                                  ?.childQuestions?.[
                                                                  index3
                                                                ]
                                                                  ?.childQuestions?.[
                                                                  index4
                                                                ]?.answer
                                                                  ?.message
                                                              }
                                                              index={index4}
                                                              required={
                                                                displayLevel4Question
                                                                  ? true
                                                                  : false
                                                              }
                                                              disabled={isDisabled()}
                                                            ></Question>
                                                          ) : null}
                                                        </Box>
                                                      );
                                                    },
                                                  )
                                                : null}
                                            </Box>
                                          </Box>
                                        );
                                      },
                                    )
                                  : null}
                              </Box>
                            </Box>
                          );
                        },
                      )
                    : null}
                </Box>
              );
            })
          : null}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '1rem',
          }}
        >
          <SubmitButton
            id="formSubmitBtn"
            data-test="save"
            disabled={isDisabled() || !isDirty}
            loading={saveDisclosureAnswersIsLoading}
          >
            {saveDisclosureAnswersIsLoading ? (
              <Spinner
                size={25}
                style={{ color: palette.common.white, pointerEvents: 'none' }}
              />
            ) : (
              <Typography>Save</Typography>
            )}
          </SubmitButton>
        </Box>
      </Form>
    </Box>
  );
};
