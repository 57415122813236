import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Box,
  IconButton,
  Typography,
  Stack,
  Divider,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

import { setMode } from 'redux/slices/modeSlice';

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user?.userData);
  const { mode } = useSelector(({ mode }) => mode);
  const icon = mode === 'light' ? faMoon : faSun;
  const newMode = mode === 'light' ? 'dark' : 'light';
  return (
    <>
      <AppBar component="header" position="sticky" sx={{ p: '6.5px 25px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              sx={{
                py: '6.5px',
                pl: '12px',
                fontWeight: 400,
                fontSize: '24px',
                lineHeight: '30px',
                letterSpacing: '0.25px',
              }}
            >
              Disclosure Settings
            </Typography>

            <Stack
              direction="row"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: '20px' }}>{user?.name}</Typography>
              <Divider orientation="vertical" sx={{ px: 1 }} />
              <IconButton
                color="inherit"
                onClick={() => dispatch(setMode(newMode))}
                sx={{ px: 2 }}
              >
                <FontAwesomeIcon icon={icon} />
              </IconButton>
            </Stack>
          </Stack>
        </Box>
      </AppBar>
    </>
  );
};

export default Header;
