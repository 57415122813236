import { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { uwmDarkTheme, uwmLightTheme } from 'lib-node-uwm-theme';
import { setMode } from 'redux/slices/modeSlice';
import { Error } from 'common/components/Error';
import { Success } from 'common/components/Success';
import Router from 'Router';

const App = () => {
  const dispatch = useDispatch();
  const { mode } = useSelector(({ mode }) => mode);
  const theme = useMemo(
    () =>
      createTheme({
        ...(mode === 'light' ? uwmLightTheme : uwmDarkTheme),
      }),
    [mode],
  );

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    if (prefersDarkMode) {
      dispatch(setMode('dark'));
    }
  }, [prefersDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <Error />
      <Success />
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
};

export default App;
