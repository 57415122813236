import { createSlice } from '@reduxjs/toolkit';

const initialState = { success: false, title: null, message: null };

const successSlice = createSlice({
  name: 'success',
  initialState,
  reducers: {
    setSuccess(state, action) {
      const { success, title, message } = action.payload;
      state.success = success;
      state.title = title;
      state.message = message;
    },
    clearSuccess: () => initialState,
  },
});

export const { setSuccess, clearSuccess } = successSlice.actions;
export default successSlice.reducer;
